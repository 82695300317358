import f1 from './images/film/1.jpg';
import f2 from './images/film/2.jpg';
import f3 from './images/film/3.jpg';
import f4 from './images/film/4.jpg';
import f5 from './images/film/5.jpg';
import f6 from './images/film/6.jpg';
import f7 from './images/film/7.jpg';
import f8 from './images/film/8.jpg';
import f9 from './images/film/9.jpg';
import f10 from './images/film/10.jpg';
import f11 from './images/film/11.jpg';
import f12 from './images/film/12.jpg';
import f13 from './images/film/13.jpg';
import f14 from './images/film/14.jpg';
import f15 from './images/film/15.jpg';
import f16 from './images/film/16.jpg';
import f17 from './images/film/17.jpg';
import f18 from './images/film/18.jpg';
import f19 from './images/film/19.jpg';
import f20 from './images/film/20.jpg';
import f21 from './images/film/21.jpg';
import f22 from './images/film/22.jpg';
import f23 from './images/film/23.jpg';
import f24 from './images/film/24.jpg';
import f25 from './images/film/25.jpg';
import f26 from './images/film/26.jpg';
import f27 from './images/film/27.jpg';
import f28 from './images/film/28.jpg';
import f29 from './images/film/29.jpg';
import f30 from './images/film/30.jpg';
import f31 from './images/film/31.jpg';
import f32 from './images/film/32.jpg';
import f33 from './images/film/33.jpg';
import f34 from './images/film/34.jpg';
import f35 from './images/film/35.jpg';
import f36 from './images/film/36.jpg';
import f37 from './images/film/37.jpg';
import f38 from './images/film/38.jpg';
import f39 from './images/film/39.jpg';
import f40 from './images/film/40.jpg';
import f41 from './images/film/41.jpg';
import f42 from './images/film/42.jpg';
import f43 from './images/film/43.jpg';
import f44 from './images/film/44.jpg';
import f45 from './images/film/45.jpg';
import f46 from './images/film/46.jpg';
import f47 from './images/film/47.jpg';
import f48 from './images/film/48.jpg';
import f49 from './images/film/49.jpg';
import f50 from './images/film/50.jpg';
import f51 from './images/film/51.jpg';
import f52 from './images/film/52.jpg';
import f53 from './images/film/53.jpg';
import f54 from './images/film/54.jpg';
import f55 from './images/film/55.jpg';
import f56 from './images/film/56.jpg';
import f57 from './images/film/57.jpg';

const filmTileData = [
    {
      img: f1,
      title: 'nyc',
      cols: 1,
    },
    {
      img: f2,
      title: 'root & bone',
      cols: 1,
    },
    {
      img: f3,
      title: 'wsp',
      cols: 1,
    },
    {
      img: f4,
      title: 'nana',
      cols: 1,
    },
    {
      img: f5,
      title: 'whitney',
      cols: 1,
    },
    {
      img: f6,
      title: 'lafayette',
      cols: 1,
    },
    {
      img: f7,
      title: 'uptown',
      cols: 1,
    },
    {
      img: f8,
      title: 'mercer',
      cols: 1,
    },
    {
      img: f9,
      title: 'nailah',
      cols: 1,
    },
    {
      img: f10,
      title: 'rockaway beach',
      cols: 1,
    },
    {
      img: f11,
      title: 'gabby',
      cols: 1,
    },
    {
      img: f12,
      title: 'central park',
      cols: 1,
    },
    {
      img: f13,
      title: 'hudson ship',
      cols: 1,
    },
    {
      img: f14,
      title: 'yasmin and icecream',
      cols: 1,
    },
    {
      img: f15,
      title: 'lea and lianna',
      cols: 1,
    },
    {
      img: f16,
      title: 'snow',
      cols: 1,
    },
    {
      img: f17,
      title: 'bobst',
      cols: 1,
    },
    {
      img: f18,
      title: 'alexia and sophia',
      cols: 1,
    },
    {
      img: f19,
      title: 'trash',
      cols: 1,
    },
    {
      img: f20,
      title: 'arlie',
      cols: 1,
    },
    {
      img: f21,
      title: 'brooklyn bridge',
      cols: 1,
    },
    {
      img: f22,
      title: 'wsp sky',
      cols: 1,
    },
    {
      img: f23,
      title: 'maya and maddie',
      cols: 1,
    },
    {
      img: f24,
      title: 'wsp in spring',
      cols: 1,
    },
    {
      img: f25,
      title: 'tribeca film festival',
      cols: 1,
    },
    {
      img: f26,
      title: 'mashell',
      cols: 1,
    },
    {
      img: f27,
      title: 'astor place parade',
      cols: 1,
    },
    {
      img: f28,
      title: 'jordan\'s birthday at trap',
      cols: 1,
    },
    {
      img: f29,
      title: 'east village hydrant',
      cols: 1,
    },
    {
      img: f30,
      title: 'anne',
      cols: 1,
    },
    {
      img: f31,
      title: 'kids and bubbles in wsp',
      cols: 1,
    },
    {
      img: f32,
      title: 'joe\'s pizza',
      cols: 1,
    },
    {
      img: f33,
      title: 'yasmin in central park',
      cols: 1,
    },
    {
      img: f34,
      title: 'ukranian festival',
      cols: 1,
    },
    {
      img: f35,
      title: 'friends in new jersey',
      cols: 1,
    },
    {
      img: f36,
      title: 'nj boardwalk',
      cols: 1,
    },
    {
      img: f37,
      title: 'last quidditch practice spring 2019',
      cols: 1,
    },
    {
      img: f38,
      title: 'bk wall',
      cols: 1,
    },
    {
      img: f39,
      title: 'greenwich sky',
      cols: 1,
    },
    {
      img: f40,
      title: 'halloween at trap',
      cols: 1,
    },
    {
      img: f41,
      title: 'amelia and tia',
      cols: 1,
    },
    {
      img: f42,
      title: 'shanghai metro',
      cols: 1,
    },
    {
      img: f43,
      title: 'm50 hidden gem',
      cols: 1,
    },
    {
      img: f44,
      title: 'huangshan',
      cols: 1,
    },
    {
      img: f45,
      title: 'gabriel',
      cols: 1,
    },
    {
      img: f46,
      title: 'lujiazui skywalk',
      cols: 1,
    },
    {
      img: f47,
      title: 'huangshan meal',
      cols: 1,
    },
    {
      img: f48,
      title: 'huangshan fog',
      cols: 1,
    },
    {
      img: f49,
      title: 'shanghai street food',
      cols: 1,
    },
    {
      img: f50,
      title: 'suzhou',
      cols: 1,
    },
    {
      img: f51,
      title: 'lujiazui riverside',
      cols: 1,
    },
    {
      img: f52,
      title: 'julia',
      cols: 1,
    },
    {
      img: f53,
      title: 'meiji shrine in tokyo',
      cols: 1,
    },
    {
      img: f54,
      title: 'people\'s square',
      cols: 1,
    },
    {
      img: f55,
      title: 'hotpot in shanghai',
      cols: 1,
    },
    {
      img: f56,
      title: 'arashiyama park',
      cols: 1,
    },
    {
      img: f57,
      title: 'friendsgiving',
      cols: 1,
    },
  ]

  export default filmTileData;