import d1 from './images/digital/1.JPG';
import d2 from './images/digital/2.JPG';
import d3 from './images/digital/3.JPG';
import d4 from './images/digital/4.JPG';
import d5 from './images/digital/5.JPG';
import d6 from './images/digital/6.JPG';
import d7 from './images/digital/7.JPG';
import d8 from './images/digital/8.JPG';
import d9 from './images/digital/9.JPG';
import d10 from './images/digital/10.JPG';
import d11 from './images/digital/11.JPG';
import d12 from './images/digital/12.JPG';
import d13 from './images/digital/13.JPG';
import d14 from './images/digital/14.JPG';
import d15 from './images/digital/15.JPG';
import d16 from './images/digital/16.JPG';
import d17 from './images/digital/17.JPG';
import d18 from './images/digital/18.JPG';
import d19 from './images/digital/19.JPG';
import d20 from './images/digital/20.JPG';
import d21 from './images/digital/21.JPG';
import d22 from './images/digital/22.JPG';
import d23 from './images/digital/23.JPG';
import d24 from './images/digital/24.JPG';
import d25 from './images/digital/25.JPG';
import d26 from './images/digital/26.JPG';
import d27 from './images/digital/27.JPG';
import d28 from './images/digital/28.JPG';
import d29 from './images/digital/29.JPG';
import d30 from './images/digital/30.JPG';
import d31 from './images/digital/31.JPG';
import d32 from './images/digital/32.JPG';
import d33 from './images/digital/33.JPG';
import d34 from './images/digital/34.JPG';
import d35 from './images/digital/35.JPG';
import d36 from './images/digital/36.JPG';
import d37 from './images/digital/37.JPG';
import d38 from './images/digital/38.JPG';
import d39 from './images/digital/39.JPG';
import d40 from './images/digital/40.JPG';
import d41 from './images/digital/41.JPG';
import d42 from './images/digital/42.JPG';
import d43 from './images/digital/43.JPG';
import d44 from './images/digital/44.JPG';
import d45 from './images/digital/45.JPG';
import d46 from './images/digital/46.JPG';
import d47 from './images/digital/47.JPG';
import d48 from './images/digital/48.JPG';
import d49 from './images/digital/49.JPG';
import d50 from './images/digital/50.JPG';
import d51 from './images/digital/51.JPG';
import d52 from './images/digital/52.JPG';
import d53 from './images/digital/53.JPG';
import d54 from './images/digital/54.JPG';
import d55 from './images/digital/55.JPG';
import d56 from './images/digital/56.JPG';
import d57 from './images/digital/57.JPG';

const digitalTileData = [
  {
    img: d1,
    title: 'anne in cooper hewitt',
    cols: 1,
  },
  {
    img: d2,
    title: 'century park',
    cols: 1,
  },
  {
    img: d3,
    title: 'm50 dark room',
    cols: 1,
  },
  {
    img: d4,
    title: 'fidi skyline',
    cols: 1,
  },
  {
    img: d5,
    title: 'lany shoes',
    cols: 1,
  },
  {
    img: d6,
    title: 'LI 4th of July',
    cols: 1,
  },
  {
    img: d7,
    title: 'pudong laundry',
    cols: 1,
  },
  {
    img: d8,
    title: 'cuban dog',
    cols: 1,
  },
  {
    img: d9,
    title: 'm50 rooftop',
    cols: 1,
  },
  {
    img: d10,
    title: 'century lake',
    cols: 1,
  },
  {
    img: d11,
    title: 'tsukji kids',
    cols: 1,
  },
  {
    img: d12,
    title: 'tsukji fish market',
    cols: 1,
  },
  {
    img: d13,
    title: 'nyc skyline',
    cols: 1,
  },
  {
    img: d14,
    title: 'shanghai roofhopping',
    cols: 1,
  },
  {
    img: d15,
    title: 'SH flower stand',
    cols: 1,
  },
  {
    img: d16,
    title: 'SH street',
    cols: 1,
  },
  {
    img: d17,
    title: 'pudong park',
    cols: 1,
  },
  {
    img: d18,
    title: 'fireworks',
    cols: 1,
  },
  {
    img: d19,
    title: 'zhujiajiao boat',
    cols: 1,
  },
  {
    img: d20,
    title: 'mt wuyi',
    cols: 1,
  },
  {
    img: d21,
    title: 'huangshan landscape',
    cols: 1,
  },
  {
    img: d22,
    title: 'hongcun',
    cols: 1,
  },
  {
    img: d23,
    title: 'century ave calligraphy',
    cols: 1,
  },
  {
    img: d24,
    title: 'fushimi inari',
    cols: 1,
  },
  {
    img: d25,
    title: 'havana kids',
    cols: 1,
  },
  {
    img: d26,
    title: 'havana street',
    cols: 1,
  },
  {
    img: d27,
    title: 'lujiazui at night',
    cols: 1,
  },
  {
    img: d28,
    title: 'fuzhou lunch',
    cols: 1,
  },
  {
    img: d29,
    title: 'vinz at cooper hewitt',
    cols: 1,
  },
  {
    img: d30,
    title: 'fuzhou laundry',
    cols: 1,
  },
  {
    img: d31,
    title: 'central park crossing',
    cols: 1,
  },
  {
    img: d32,
    title: 'lianjiang',
    cols: 1,
  },
  {
    img: d33,
    title: 'lianjiang rickshaw',
    cols: 1,
  },
  {
    img: d34,
    title: 'whitney pins',
    cols: 1,
  },
  {
    img: d35,
    title: 'whitney reflection',
    cols: 1,
  },
  {
    img: d36,
    title: 'tianzifang',
    cols: 1,
  },
  {
    img: d37,
    title: 'yu garden',
    cols: 1,
  },
  {
    img: d38,
    title: 'japan',
    cols: 1,
  },
  {
    img: d39,
    title: 'lany',
    cols: 1,
  },
  {
    img: d40,
    title: 'century ave station',
    cols: 1,
  },
  {
    img: d41,
    title: 'jingan girls',
    cols: 1,
  },
  {
    img: d42,
    title: 'jingan tadpoles',
    cols: 1,
  },
  {
    img: d43,
    title: 'central parkers',
    cols: 1,
  },
  {
    img: d44,
    title: 'cuban sunset',
    cols: 1,
  },
  {
    img: d45,
    title: 'havana market',
    cols: 1,
  },
  {
    img: d46,
    title: 'brooklyn',
    cols: 1,
  },
  {
    img: d47,
    title: 'tsukiji',
    cols: 1,
  },
  {
    img: d48,
    title: 'fuxing man',
    cols: 1,
  },
  {
    img: d49,
    title: 'hill myna',
    cols: 1,
  },
  {
    img: d50,
    title: 'el capitolio',
    cols: 1,
  },
  {
    img: d51,
    title: 'pudong sky',
    cols: 1,
  },
  {
    img: d52,
    title: 'cuba',
    cols: 1,
  },
  {
    img: d53,
    title: 'kyoto guard',
    cols: 1,
  },
  {
    img: d54,
    title: 'casa de pilar',
    cols: 1,
  },
  {
    img: d55,
    title: 'arashiyama',
    cols: 1,
  },
  {
    img: d56,
    title: 'japanese metro',
    cols: 1,
  },
  {
    img: d57,
    title: 'lady in color',
    cols: 1,
  },
]

export default digitalTileData;